<template>
  <div id="largescreen" :style="_isMobile() ? {} : largescreenPcStyle">
    <el-row :gutter="10">
      <el-col :lg="24" :md="24" :sm="24" :xs="24">
        <top-header/>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="padding: 10px;height: calc(100% - 60px);">
      <el-col :lg="12" :md="18" :sm="24" :xs="24">
        <BodyLeftTop style="margin-bottom: 10px;"/>
        <BodyLeftSecond />
        <BodyLeftCenter />
        <BodyLeftFourth />
        <BodyLeftBottom />
      </el-col>

      <el-col :lg="12" :md="18" :sm="24" :xs="24">
        <BodyRightTop style="margin-bottom: 10px;"/>
        <BodyRightSecond />
        <BodyRightCenter />
        <BodyRightFourth />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TopHeader from "./components/topHeader";

import BodyLeftTop from './components/bodyLeftTop/index.vue'
import BodyLeftSecond from './components/bodyLeftSecond'
import BodyLeftCenter from './components/bodyLeftCenter/index.vue'
import BodyLeftFourth from './components/bodyLeftFourth/index.vue'
import BodyLeftBottom from './components/bodyLeftBottom1/index.vue'

import BodyRightTop from './components/bodyRightTop/index.vue'
import BodyRightSecond from './components/bodyRightSecond/index.vue'
import BodyRightCenter from './components/bodyRightCenter/index.vue'
import BodyRightFourth from './components/bodyRightFourth1/index.vue'
import mockjs from 'mockjs'

export default {
  name: "index",
  components: {
    TopHeader,
    BodyLeftTop,
    BodyLeftSecond,
    BodyLeftCenter,
    BodyLeftFourth,
    BodyLeftBottom,

    BodyRightTop,
    BodyRightSecond,
    BodyRightCenter,
    BodyRightFourth,
  },
  data() {
    return {
      largescreenPcStyle: {
        position: 'fixed',
        top: 0,
        left: 0,
      },
    }
  },
  created() {
    
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
::v-deep#largescreen {
  // height: calc(100vh);
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  padding: 0;
  background-image: url("../assets/images/largescreen/bg.png");

  .el-col {
    height: 100%;
    position: relative;

    .date-range {
      position: absolute;
      right: 5px;
      z-index: 9;

      .el-date-editor {
        background: transparent;
        border-color: rgb(0, 186, 255);
        width: 250px;

        .el-icon-date {
          color: #fff;
        }

        .el-range-separator {
          color: #fff;
        }

        .el-range__close-icon {
          color: #fff;
        }

        .el-range-input {
          background: transparent;
          color: #fff;
        }
      }
    }

    .map-item-amounts {
      position: absolute;
      z-index: 9;
      left: 10px;
      top: 30px;
      color: #fff;
      font-size: 16px;
      color: #24CFF4;
    }
  }

  .el-carousel {
    height: calc(33.3%);
    //margin-bottom: 10px;
    .el-carousel__container {
      height: 100%;
    }

    .el-carousel__indicator--horizontal {
      padding: 3px;
    }
  }
}
</style>

<style>
/* 模块标题图标 */
.title-icon {
  height: 1.333333rem /* 80/60 */;
}

/* 轮播表样式 */
.dv-scroll-board .header {
  box-sizing: border-box;
  border: 1px solid #7edae8;
  background-color: none;
  /* height: 0.616rem; */
}

.dv-scroll-board .header .header-item {
  /* height: 100% !important;
  line-height: 0.616rem !important; */
  padding: 0;
  color: #01c1ca;
  font-weight: 900;
  font-size: .166667rem /* 10/60 */ !important;
}
.dv-scroll-board .rows .ceil{
  padding: 0;
  font-size: .166667rem /* 10/60 */;
  overflow: clip;
  text-overflow: clip;
}
</style>
