import * as request from '@/utils/request'

// 在港集装箱查询-按照 航线类型
export const queryDInContainerList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDInContainerList',
    method: 'get',
    params: params
  })
}

// 在港集装箱查询-按照集装箱类型，进口,出口，中转
export const queryDInContaierIeFlagList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDInContaierIeFlagList',
    method: 'get',
    params: params
  })
}

// 集装箱在港堆存时间-查询
export const queryDContainerStorageList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDContainerStorageList',
    method: 'get',
    params: params
  })
}