<template>
  <div class="num-card-div">
    <span v-for="(num, index) in numVal" :key="index">{{ num }}</span>
  </div>
</template>

<script>
export default {
  name: 'HpscScreenVueNumCard',
  props: {
    numVal: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.num-card-div {
  margin-left: 2%;
  span {
    font-size: 0.2rem;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 8%;
    background-image: url("../../../../assets/images/largescreen/num-bg.png");
  }
}
</style>