import * as request from '@/utils/request'

// 集装箱业务动态查询
export const queryDShipOperationList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDShipOperationList',
    method: 'get',
    params: params,
  })
}
