import * as request from '@/utils/request'

// 查验方式查-查询
export const queryManifestCheckInfoHead = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryManifestCheckInfoHead',
    method: 'get',
    params: params
  })
}

// 查验类型查询-查询
export const queryManifestExamTypeHead = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryManifestExamTypeHead',
    method: 'get',
    params: params
  })
}