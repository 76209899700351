<template>
  <div id="business-chart-table-box">
    <dv-scroll-board ref="business-chart-table" id="business-chart-table" :config="config"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'

export default {
  mixins: [resize],
  props: {
    timedComplainDataList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      chart: null,
      config: {},
      tableHeaderWidth: 0
    }
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.setConfig()
    })
  },
  watch: {
    timedComplainDataList: {
      handler(val) {
        this.$refs['business-chart-table'].updateRows(this.timedComplainDataList)
      },
      deep: true
    },
  },
  methods: {
    setConfig() {
      this.tableHeaderWidth = document.querySelector('#business-chart-table').offsetWidth
      this.config = {
        index: true,
        header: ['内外贸', '船名', '航次', '起运港', '目的港', '提运单', '业务类型', '时间'],
        data: this.timedComplainDataList,
        // columnWidth: [50, 70, 80, 80, 110, 110, 110, 110, 150],
        columnWidth: [
          this.tableHeaderWidth * 0.053,
          this.tableHeaderWidth * 0.08,
          this.tableHeaderWidth * 0.089,
          this.tableHeaderWidth * 0.089,
          this.tableHeaderWidth * 0.126,
          this.tableHeaderWidth * 0.126,
          this.tableHeaderWidth * 0.126,
          this.tableHeaderWidth * 0.126,
          this.tableHeaderWidth * 0.18
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        rowNum: 5,
        align: ['center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center'],
        headerHeight: this.cusFontSize(0.516)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#business-chart-table-box {
  display: flex;
  // background: rgba(125, 155, 243, .2);
  height: 100%;
  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    // background: rgba(125, 155, 243, .2);
    position: relative;

    .charts-icon{
      width: 20px;
      height: 25px;
      position: absolute;
      left: 15px;
      top: 0;
    }
    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }

  #business-chart-table {
    padding: 10px 0;
    height: calc(100% - 20px);
  }
}
</style>