<template>
  <div id="chart-line-pv-pv-box">
    <div class="chart-title">
      <span>年度</span>
      <el-select class="year-select" v-model="year" size="small" @change="yearChange">
        <el-option v-for="(item, index) in years" :key="index" :value="item.value" :label="item.key" />
      </el-select>
    </div>
    <div class="import-info">
      <div class="import-ava">
        进口平均 <span style="color: #00acff">{{chartData.iAva}}</span> 天
      </div>
      <div class="export-ava">
        出口平均 <span style="color: #ffcd00">{{chartData.eAva}}</span> 天
      </div>
    </div>
    <div id="conta-timeline-line-pv"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'
import moment from 'moment';

export default {
  mixins: [resize],
  props: {
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
      mapJsonData: {
        pv: [],
        visitDate: [],
      },
      year: '1',
      years: [
        {key: moment().add(-2, 'y').format("YYYY"), value: moment().add(-2, 'y').format("YYYY")},
        {key: moment().add(-1, 'y').format("YYYY"), value: moment().add(-1, 'y').format("YYYY")},
        {key: moment().year(), value: moment().year()},
        {key: '近一年', value: '1'},
      ]
    }
  },
  watch: {
    chartData: {
      handler(newVal, oldVal) {
        this.initChart();
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(document.getElementById('conta-timeline-line-pv'))
      this.setOptions()
    },
    setOptions() {
      this.chart.setOption({
        color: ['#00acff', '#fb9800'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          formatter: function (params) {
            var relVal = params[0].name
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value + '天'
            }
            return relVal
          }
        },
        legend: {
          textStyle: {
            color: '#fff',
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          data: ['进口平均', '出口平均']
        },
        grid: {
          top: '5%',
          left: '0',
          right: '0',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            name: '年份',
            type: 'category',
            axisTick: { show: false },
            axisLabel: {
              textStyle:{
                color:'#7edae8',  //坐标的字体颜色
                fontSize: this.cusFontSize(0.166667 /* 10/60 */)
              },
            },
            data: this.chartData.months
          }
        ],
        yAxis: [
          {
            type: 'value',
            interval: 100,
            axisLabel: {
              textStyle:{
                color:'#7edae8',  //坐标的字体颜色
                fontSize: this.cusFontSize(0.2 /* 12/60 */)
              },
            },
            splitLine: {
              //分割线配置
              lineStyle: {
                color: "rgb(255,255,255,0)",
              },
            },
          }
        ],
        series: [
          {
            name: '进口平均',
            type: 'line',
            smooth:true,
            emphasis: {
              focus: 'series'
            },
            areaStyle:{         //2.基础面积图。区域填充样式
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {offset: 0, color: 'rgba(0, 172, 255, 0)'},
                  {offset: 1, color: 'rgba(0, 172, 255, 0.5)'},
                ]
              }
            },
            data: this.chartData.iData
          },
          {
            name: '出口平均',
            type: 'line',
            smooth:true,
            emphasis: {
              focus: 'series'
            },
            areaStyle:{         //2.基础面积图。区域填充样式
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {offset: 0, color: 'rgba(251, 152, 0, 0)'},
                  {offset: 1, color: 'rgba(251, 152, 0, 0.5)'},
                ]
              }
            },
            data: this.chartData.eData
          },
        ]
      })
    },
    // 年份改变方法
    yearChange(value) {
      this.$emit('changeYear', value)
    }
  }
}
</script>

<style lang="scss" scoped>
#chart-line-pv-pv-box {
  height: calc(100%);

  .chart-title {
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    position: relative;
    font-size: .166667rem /* 10/60 */;
  }

  .import-info {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    color: #fff;
    text-align: center;
    .import-ava {
      border-right: 1px solid rgb(217, 217, 217, 0.3);
    }
    .import-ava,
    .export-ava {
      padding: 0 10px;
      span {
        font-size: 0.33rem;
        font-weight: 500;
      }
    }
  }

  #conta-timeline-line-pv {
    height: calc(100%);
  }
}

.year-select {
  margin-left: .083333rem /* 5/60 */;
  width: .833333rem /* 50/60 */;
}

::v-deep .year-select {
  .el-input__inner{
    height: .25rem /* 15/60 */;
    padding: 0 .083333rem /* 5/60 */;
    font-size: .166667rem /* 10/60 */;
    border: 1px solid #409EFF;
    color: #7edae8;
    background-color: #033065;
  }
  .el-input__suffix {
    display: none;
  }
}
</style>