import * as request from '@/utils/request'

// 集装箱箱量统计查询--月度
export const queryDContainerMonthList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDContainerMonthList',
    method: 'post',
    data: params
  })
}

// 车辆进出闸统计查询-月度
export const queryDVehicleMonthList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDVehicleMonthList',
    method: 'post',
    data: params
  })
}

// 车辆进出闸统计查询-月度
export const queryDVehicleTotalList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDVehicleTotalList',
    method: 'post',
    data: params
  })
}