<template>
  <el-col :lg="24" :md="12" :sm="24" :xs="24" style="height: calc(17%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      船舶作业
    </div>
    <el-col :lg="24" :md="12" :sm="24" :xs="24">
      <div id="ship-chart-table-box">
        <dv-scroll-board ref="chart-table" id="chart-table" :config="config"/>
      </div>
    </el-col>
  </el-col>
</template>

<script>
import resize from '@/utils/resize'
import { queryDContaOperationList } from './api'
import moment from 'moment'

export default {
  mixins: [resize],
  name: "HpscScreenVueBodyLeftSecond",
  data() {
    return {
      chart: null,
      timedComplainDataList: [],
      config: {},
      tableHeaderWidth: 0,
      pageNum: 1,
      pageSize: 10
    }
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.setConfig()
      this.getDContaOperationList()
    })
  },
  watch: {
    
  },
  methods: {
    // 船舶作业查询
    async getDContaOperationList() {
      const headerParams = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const res = await queryDContaOperationList({}, headerParams)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        const arr = []
        if (rows.length > 0) {
          for (let i = 0; i < rows.length; i++) {
            const dataArr = [
              rows[i].ieflag === 'I' ? '内贸' : '外贸',
              rows[i].conta + '/' + rows[i].voyage,
              rows[i].berthingTime != null && rows[i].berthingTime.length>5 ? moment(rows[i].berthingTime).format('YY-MM-DD hh:mm') : '---',
              rows[i].departureTime != null && rows[i].departureTime.length>5 ? moment(rows[i].departureTime).format('YY-MM-DD hh:mm') : '---',
              rows[i].ieload,
              // rows[i].startTime ? moment(rows[i].startTime).format('YY-MM-DD hh:mm') : '',
              rows[i].startTime != null && rows[i].startTime.length>5 ? moment(rows[i].startTime).format('YY-MM-DD hh:mm') : '---',
              // rows[i].endTime ? moment(rows[i].endTime).format('YY-MM-DD hh:mm') : '',
              rows[i].endTime != null && rows[i].endTime.length>5 ? moment(rows[i].endTime).format('YY-MM-DD hh:mm') : '---',
              rows[i].contaNum + ' / ' + rows[i].totalNum,
              rows[i].schedule,
            ]
            arr.push(dataArr)
          }
          this.timedComplainDataList = arr
          this.$refs['chart-table'].updateRows(this.timedComplainDataList)
        }
      }
    },
    setConfig() {
      this.tableHeaderWidth = document.querySelector('#chart-table').offsetWidth
      this.config = {
        index: true,
        header: ['内外贸', '船名/航次', '靠泊时间', '离泊时间', '装船/卸船', '开工时间', '完工时间', '完成箱/计划箱', '进度'],
        data: this.timedComplainDataList,
        // columnWidth: [50, 70, 110, 150, 150, 80, 150, 150, 110, 110],
        columnWidth: [
          this.tableHeaderWidth * 0.044,
          this.tableHeaderWidth * 0.062,
          this.tableHeaderWidth * 0.097,
          this.tableHeaderWidth * 0.132,
          this.tableHeaderWidth * 0.132,
          this.tableHeaderWidth * 0.07,
          this.tableHeaderWidth * 0.132,
          this.tableHeaderWidth * 0.132,
          this.tableHeaderWidth * 0.097,
          this.tableHeaderWidth * 0.097,
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        rowNum: 3,
        align: ['center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center'],
        headerHeight: this.cusFontSize(0.516)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#ship-chart-table-box {
  display: flex;
  // background: rgba(125, 155, 243, .2);
  height: 100%;
  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    // background: rgba(125, 155, 243, .2);
    position: relative;

    .charts-icon{
      width: 20px;
      height: 25px;
      position: absolute;
      left: 15px;
      top: 0;
    }
    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }

  #chart-table {
    padding: 10px 0;
    height: calc(100% - 20px);
  }
}

// ::v-deep .dv-scroll-board .rows .ceil{
//   font-size: 10px;
// }
</style>