<template>
  <div id="chart-line-pv-pv-box">
    <!-- <div class="chart-title">
      <img class="charts-icon" :src="require('@/assets/images/largescreen/charts-icon.png')" alt="">
      <dv-decoration-1/>
      <span>进出口集装箱</span>
    </div> -->
    <!-- <div class="year-info">年度：{{moment().year()}}</div> -->
    <div id="conta-pie-pv"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'
import moment from 'moment';

export default {
  mixins: [resize],
  name: 'HpscScreenVueBodyLeftFourth1',
  props: {
    chartData: {
      type: Object
    }
  },
  data() {
    return {
      moment,
      chart: null,
      mapJsonData: {
        pv: [],
        visitDate: [],
      },
    }
  },
  watch: {
    chartData: {
      handler(newVal, oldVal) {
        this.initChart();
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(document.getElementById('conta-pie-pv'))
      this.setOptions()
    },
    setOptions() {
      this.chart.setOption({
        color: ['#e87b2c', '#9bff51', '#ffe200', '#0092ff', '#00dfcc', '#A9A9A9'],
        title: {
          text: moment().year() + '年',
          // text: '2023' + '年',
          left:"center",
          top: '30%',
          textStyle: {
            color: '#01c1ca',
            fontSize: this.cusFontSize(0.2 /* 12/60 */)
          }
        },
        tooltip: {
          trigger: 'item',
          textStyle: {
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          formatter: function (params) {
            const relVal = 
              params.marker + params.name + '<br/>' + 
              'TEU: ' + params.value + '<br/>' +
              '占比: ' + params.percent + '%'
            return relVal
          }
        },
        legend: [
          {
            bottom: '25%',
            left: 'center',
            itemWidth: 4,
            itemHeight: 4,
            textStyle: {
              color: '#fff',
              fontSize: this.cusFontSize(0.166667 /* 10/60 */)
            },
            data: this.chartData.nameList1
          },
          {
            bottom: '15%',
            left: 'center',
            itemWidth: 4,
            itemHeight: 4,
            textStyle: {
              color: '#fff',
              fontSize: this.cusFontSize(0.166667 /* 10/60 */)
            },
            data: this.chartData.nameList2
          },
        ],
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '35%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            // emphasis: {
            //   label: {
            //     show: false,
            //     fontSize: 12,
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              show: false
            },
            data: this.chartData.goodsPieList
          }
        ]
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#chart-line-pv-pv-box {
  // background: rgba(125, 155, 243, .2);
  height: calc(100%);
  //margin-bottom: 10px;
  .year-info {
    font-size: .2rem /* 12/60 */;
    color: #fff;
    position: relative;
    top: .2rem /* 12/60 */;
  }

  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    // background: rgba(125, 155, 243, .2);
    position: relative;

    .charts-icon{
      width: 20px;
      height: 25px;
      position: absolute;
      left: 15px;
      top: 0;
    }
    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }

  #conta-pie-pv {
    height: calc(130%);
  }
}
</style>