<template>
  <el-col :lg="24" :md="24" :sm="24" :xs="24"  style="height: calc(20%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      货物数据
    </div>
    <el-col :lg="18" :md="24" :sm="24" :xs="24">
      <BodyLeftFourth1 :chartData="goodsTableData" :yearList="yearList"/>
    </el-col>
    <el-col :lg="6" :md="24" :sm="24" :xs="24">
      <BodyLeftFourth2 :chartData="goodsPieData"/>
    </el-col>
  </el-col>
</template>

<script>
import BodyLeftFourth1 from './components/bodyLeftFourth1.vue'
import BodyLeftFourth2 from './components/bodyLeftFourth2.vue'
import { queryDCargoTranTotalList, queryDCargoTranTotalByYearList } from './api'
import moment from 'moment';

export default {
  name: 'HpscScreenVueIndex',
  components: {
    BodyLeftFourth1,
    BodyLeftFourth2
  },
  data() {
    return {
      dateObj: new Date(),
      goodsPieData: {
        goodsPieList: [],
        nameList1: [],
        nameList2: [],
      },
      goodsTableData: [],
      yearList: []
    };
  },

  mounted() {
    this.getDCargoTranTotalList()
    this.getDCargoTranTotalByYearList()
  },

  methods: {
    // 货物运输统计表 -货物年度统计查询(饼图)
    async getDCargoTranTotalList() {
      const res = await queryDCargoTranTotalList({year: this.dateObj.getFullYear()})
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        if (rows.length > 0) {
          for (let i = 0; i < rows.length; i++) {
            this.goodsPieData.goodsPieList.push({name: rows[i].cargo, value: rows[i].datatext1})
            if (i < 3) {
              this.goodsPieData.nameList1.push(rows[i].cargo)
            } else {
              this.goodsPieData.nameList2.push(rows[i].cargo)
            }
          }
        }
      }
    },
    // 货物运输统计表 -货物每年统计查询(列表)
    async getDCargoTranTotalByYearList() {
      const res = await queryDCargoTranTotalByYearList()
      if (res.code === 200 && res.data) {
        const cargoList = res.data.cargoList
        const yearList = res.data.yearList
        if (cargoList.length > 0) {
          this.goodsTableData = JSON.parse(JSON.stringify(cargoList))
          this.goodsTableData.forEach((item, index) => {
            item.splice(2, 0, '')
            if (cargoList.length >= 5) {
              item.splice(5, 0, '')
            }
            if (cargoList.length === 7)
              item.splice(8, 0, '')
          })
        }
        if (yearList.length > 0) {
          let finYearList = ['货物']
          for (let i = 0; i < yearList.length; i++) {
            const addYearList = ['进口', yearList[i], '出口']
            finYearList = [...finYearList, ...addYearList]
          }
          this.yearList = finYearList
        } else {
          this.yearList = ['货物', '进口', moment().add(-2, 'y').format("YYYY"), '出口', '进口', moment().add(-1, 'y').format("YYYY"), '出口', '进口', moment().year(), '出口']
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>