<template>
  <div id="chart-line-pv-pv-box">
    <!-- <div class="chart-title">
      <img class="charts-icon" :src="require('@/assets/images/largescreen/charts-icon.png')" alt="">
      <dv-decoration-1/>
      <span>箱类型</span>
    </div> -->
    <div class="inport-info">
      <div class="info-box" v-for="(item, index) in chartData.ieoTotalList" :key="index">
        <div>
          {{item.name}}
        </div>
        <div class="num-div">
          {{item.value}} <span style="color: #fff">TEU</span>
        </div>
      </div>
    </div>
    <div id="inport-conta-bar-pv"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'

export default {
  mixins: [resize],
  name: 'HpscScreenVueBodyRightTop1',
  props: {
    chartData: {
      type: Object
    }
  },
  data() {
    return {
      chart: null,
      mapJsonData: {
        pv: [],
        visitDate: [],
      },
      barBorderRadius: [3, 3, 3, 3]
    }
  },
  watch: {
    chartData: {
      handler(newVal, oldVal) {
        this.initChart();
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(document.getElementById('inport-conta-bar-pv'))
      this.setOptions()
    },
    setOptions() {
      this.chart.setOption({
        color: ['#00acff', '#ffcd00', '#2cb48a'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          formatter: function (params) {
            let relVal = params[0].name
            for (let i = params.length - 1, l = params.length; i >= 0; i--) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value + '万'
            }
            return relVal
          }
        },
        legend: {
          x: 'center',
          itemWidth: 16,
          itemHeight: 6,
          bottom: window.innerWidth < 2304 ? this.cusFontSize(0.233333 /* 14/60 */) : this.cusFontSize(0.333333 /* 20/60 */),
          textStyle: {
            color: '#fff',
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          data: ['进口箱', '出口箱', '中转箱']
        },
        grid: {
          top: this.cusFontSize(0.333333 /* 20/60 */),
          left: '10',
          right: '0',
          bottom: this.cusFontSize(0.583333 /* 35/60 */),
          containLabel: true
        },
        xAxis: [
          {
            name: '航线',
            type: 'category',
            axisTick: { show: false },
            axisLabel: {
              textStyle:{
                color:'#7edae8',  //坐标的字体颜色
                fontSize: this.cusFontSize(0.166667 /* 10/60 */)
              },
            },
            data: this.chartData.journeyList
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 4,
            name: '单位: 万',
            nameTextStyle: {
              color: '#7edae8',
              fontSize: this.cusFontSize(0.166667 /* 10/60 */),
              padding: window.innerWidth < 2304 ? [0, 0, -8, 10] : [0, 0, 0, 20]
            },
            axisLabel: {
              textStyle:{
                color:'#7edae8',  //坐标的字体颜色
                fontSize: this.cusFontSize(0.2 /* 12/60 */),
              },
            },
            splitLine: {
              //分割线配置
              lineStyle: {
                color: "rgb(255,255,255,0)",
              },
            },
          }
        ],
        series: [
          {
            name: '进口箱',
            type: 'bar',
            stack: 'IO',
            barMaxWidth: this.cusFontSize(0.333333 /* 20/60 */),
            emphasis: {
              focus: 'series'
            },
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'#00acff'    //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: this.barBorderRadius
              }
            },
            data: this.chartData.iData
          },
          {
            name: '出口箱',
            type: 'bar',
            stack: 'IO',
            barMaxWidth: this.cusFontSize(0.333333 /* 20/60 */),
            emphasis: {
              focus: 'series'
            },
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'#fb9800'    //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: this.barBorderRadius
              }
            },
            data: this.chartData.eData
          },
          {
            name: '中转箱',
            type: 'bar',
            stack: 'IO',
            barMaxWidth: this.cusFontSize(0.333333 /* 20/60 */),
            emphasis: {
              focus: 'series'
            },
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'#fb9800'    //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            },
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: this.barBorderRadius
              }
            },
            data: this.chartData.oData
          },
        ]
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#chart-line-pv-pv-box {
  // background: rgba(125, 155, 243, .2);
  height: calc(100%);
  //margin-bottom: 10px;

  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    // background: rgba(125, 155, 243, .2);
    position: relative;

    .charts-icon{
      width: 20px;
      height: 25px;
      position: absolute;
      left: 15px;
      top: 0;
    }
    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }

  .inport-info {
    position: relative;
    top: 0;
    display: flex;
    color: #fff;
    font-size: .166667rem /* 10/60 */;
    text-align: center;
    justify-content: center;
    .info-box:not(:last-child) {
      border-right: 1px solid rgb(217, 217, 217, 0.3);
    }
    .info-box {
      padding: 0 2px;
      .num-div {
        font-weight: 900;
        color: #00f6ff;
        span {
          color: #fff;
          font-weight: 100;
        }
      }
    }
  }

  #inport-conta-bar-pv {
    height: calc(100%);
  }
}
</style>