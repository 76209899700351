<template>
  <div id="chart-line-pv-pv-box">
    <div class="chart-title">
      <img class="charts-icon" :src="require('@/assets/images/largescreen/title-img.png')" alt="">
      <span>进出闸车次(辆)</span>
      <NumCard :numVal="chartData.fullTotal"/>
    </div>
    <div id="car-bar-pv"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'
import NumCard from './NumCard.vue'
import echarts from 'echarts'

export default {
  mixins: [resize],
  name: "bodyLeftCenter",
  components: {NumCard},
  props: {
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
      mapJsonData: {
        pv: [],
        visitDate: [],
      },
    }
  },
  watch: {
    chartData: {
      handler(newVal, oldVal) {
        this.initChart();
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(document.getElementById('car-bar-pv'))
      this.setOptions()
    },
    setOptions() {
      this.chart.setOption({
        color: ['#00acff', '#ffcd00'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          formatter: function (params) {
            var relVal = params[0].name + '年'
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value + '万'
            }
            return relVal
          }
        },
        legend: {
          x: "center",
          bottom: window.innerWidth < 2304 ? this.cusFontSize(0.233333 /* 14/60 */) : this.cusFontSize(0.333333 /* 20/60 */),
          itemWidth: 16,
          itemHeight: 6,
          textStyle: {
            color: "#fff",
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          data: ["进口", "出口"],
        },
        grid: {
          top: this.cusFontSize(0.333333) /* 20/60 */,
          left: "0",
          right: "0",
          bottom: this.cusFontSize(0.583333 /* 35/60 */),
          containLabel: true,
        },
        xAxis: [
          {
            name: '年份',
            type: 'category',
            axisTick: { show: false },
            axisLabel: {
              textStyle:{
                color:'#7edae8',  //坐标的字体颜色
                fontSize: this.cusFontSize(0.166667 /* 10/60 */)
              },
            },
            data: this.chartData.years
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 4,
            name: '单位: 万',
            nameTextStyle: {
              color: '#7edae8',
              fontSize: this.cusFontSize(0.166667 /* 10/60 */),
              padding: window.innerWidth < 2304 ? [0, 0, -8, 8] : null
            },
            axisLabel: {
              textStyle:{
                color:'#7edae8',  //坐标的字体颜色
                fontSize: this.cusFontSize(0.2 /* 10/60 */)
              },
            },
            splitLine: {
              //分割线配置
              lineStyle: {
                color: "rgb(255,255,255,0)",
              },
            },
          }
        ],
        series: [
          // {
          //   name: '进口',
          //   type: 'bar',
          //   barGap: 0,
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   barMaxWidth: 10,
          //   data: this.chartData.iTotal,
          // },
          {
            name: '进口',
            type: 'custom',
            data: this.chartData.iTotal,
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              return {
                type: 'group',
                children: [
                  {
                    type: 'CubeLeft',
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: '#00acff'
                    }
                  },
                  {
                    type: 'CubeRight',
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: '#00c4ff',
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      api,
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: '#33d0ff',
                    }
                  },
                ]
              }
            }
          },
          // {
          //   name: '出口',
          //   type: 'bar',
          //   emphasis: {
          //     focus: 'series'
          //   },
          //   barMaxWidth: 10,
          //   data: this.chartData.oTotal,
          // },
          {
            name: '出口',
            type: 'custom',
            data: this.chartData.oTotal,
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const offsetX = this.cusFontSize(0.333333 /* 20/60 */);
              return {
                type: 'group',
                children: [
                  {
                    type: 'CubeLeft',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0] + offsetX,
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]).map((item, i) => (i === 0 ? item + offsetX : item)),
                    },
                    style: {
                      fill: '#fb9800'
                    }
                  },
                  {
                    type: 'CubeRight',
                    shape: {
                      api,
                      xValue: api.value(1),
                      yValue: api.value(0),
                      x: location[0] + offsetX,
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]).map((item, i) => (i === 0 ? item + offsetX : item)),
                    },
                    style: {
                      fill: '#ecb600',
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0] + offsetX,
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0]).map((item, i) => (i === 0 ? item + offsetX : item)),
                    },
                    style: {
                      fill: '#ffd648',
                    }
                  },
                ]
              }
            }
          },
        ]
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#chart-line-pv-pv-box {
  height: calc(100%);
  .chart-title {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    padding: 0px 10px 5px 10px;
    position: relative;

    .charts-icon{
      width: .416667rem /* 25/60 */;
      height: .416667rem /* 25/60 */;
    }
  }

  #car-bar-pv {
    height: calc(100%);
  }
}
</style>
