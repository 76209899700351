<template>
  <div id="goods-chart-table-box">
    <div class="chart-title">
      <!-- <span>年度</span>
      <el-select class="year-select" v-model="year" size="small">
        <el-option v-for="(item, index) in years" :key="index" :value="item" :label="item" />
      </el-select> -->
    </div>
    <dv-scroll-board ref="good-chart-table" id="good-chart-table" :config="config"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'
import moment from 'moment';

export default {
  mixins: [resize],
  name: 'HpscScreenVueBodyLeftFourth1',
  props: {
    chartData: {
      type: Array
    },
    yearList: {
      type: Array
    },
  },
  data() {
    return {
      chart: null,
      timedComplainDataList: [],
      config: {},
      tableHeaderWidth: 0,
      year: '2023',
    }
  },

  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.setConfig()
    })
  },
  watch: {
    chartData: {
      handler(val) {
        this.setConfig()
        this.$refs['good-chart-table'].updateRows(this.chartData)
      },
      deep: true
    },
  },
  methods: {
    setConfig() {
      this.tableHeaderWidth = document.querySelector('#good-chart-table').offsetWidth
      let columnWidth = [
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
        this.tableHeaderWidth * 0.103,
      ]
      // 根据有几个年份动态设置列宽
      if (this.yearList.length === 4) {
        columnWidth = [
          this.tableHeaderWidth / 4,
          this.tableHeaderWidth / 4,
          this.tableHeaderWidth / 4,
          this.tableHeaderWidth / 3.5,
        ]
      } else if (this.yearList.length === 7) {
        columnWidth = [
          this.tableHeaderWidth / 7,
          this.tableHeaderWidth / 7,
          this.tableHeaderWidth / 7,
          this.tableHeaderWidth / 7,
          this.tableHeaderWidth / 7,
          this.tableHeaderWidth / 7,
          this.tableHeaderWidth / 6.6,
        ]
      }
      this.config = {
        // header: ['货物', '进口', moment().add(-2, 'y').format("YYYY"), '出口', '进口', moment().add(-1, 'y').format("YYYY"), '出口', '进口', moment().year(), '出口'],
        header: this.yearList,
        data: this.chartData,
        // columnWidth: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        columnWidth: columnWidth,
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        rowNum: 5,
        align: ['center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center', 'center'],
        headerHeight: this.cusFontSize(0.516)
      }
    },
  }
};
</script>

<style lang="scss" scoped>
#goods-chart-table-box {
  height: 100%;
  .chart-title {
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    position: relative;
  }

  #good-chart-table {
    height: calc(100% - 20px);
  }
}

.year-select {
  width: 80px;
}

::v-deep .year-select .el-input__inner {
  color: #7edae8;
  background-color: #033065;
}

::v-deep .year-select {
  .el-input__inner{
    height: 20px;
  }
  .el-input__suffix {
    top: 5px;
  }
  // .el-input__icon {
  //   line-height: inherit;
  // }
  // .el-input__suffix-inner {
  //   display: inline-block;
  // }
}

::v-deep .dv-scroll-board {
  border-bottom: 1px solid #7edae8;
}

::v-deep .header-item:nth-of-type(3n) {
  margin-top: -0.1666rem;
}

::v-deep .header-item:nth-of-type(3n-1) {
  // margin-top: 5px;
}

::v-deep .header-item:nth-of-type(1) {
  border-right: 1px solid #01c1ca;
}

::v-deep .header-item:nth-of-type(4) {
  border-right: 1px solid #01c1ca;
}

::v-deep .header-item:nth-of-type(7) {
  border-right: 1px solid #01c1ca;
  // margin-top: 5px;
}

::v-deep .header-item:nth-of-type(10) {
  // margin-top: 5px;
}

::v-deep .dv-scroll-board .rows .ceil:nth-of-type(1){
  border-right: 1px solid #01c1ca;
  border-left: 1px solid #01c1ca;
}

::v-deep .dv-scroll-board .rows .ceil:nth-of-type(4){
  border-right: 1px solid #01c1ca;
}

::v-deep .dv-scroll-board .rows .ceil:nth-of-type(7){
  border-right: 1px solid #01c1ca;
}

::v-deep .dv-scroll-board .rows .ceil:nth-of-type(10){
  border-right: 1px solid #01c1ca;
}


// ::v-deep .header-item:nth-of-type(6) {
//   color: red;
// }

// ::v-deep .header-item:nth-of-type(9) {
//   color: red;
// }

</style>