<template>
  <el-col :lg="24" :md="24" :sm="24" :xs="24"  style="height: calc(20%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      查验
    </div>
    
    <div style="width: 100%">
      <div class="chart-title">
        <span>年度</span>
        <el-select class="year-select" v-model="year" size="small" @change="yearChange">
          <el-option v-for="(item, index) in years" :key="index" :value="item.value" :label="item.key" />
        </el-select>
      </div>
      <el-col :lg="12" :md="24" :sm="24" :xs="24" style="padding: 0">
        <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <BodyRightFourth1 ref="BodyRightFourth1"/>
        </el-col>
        <!-- <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <BodyRightFourth2 :timed-complain="timedComplain"/>
        </el-col> -->
      </el-col>
      <el-col :lg="12" :md="24" :sm="24" :xs="24" style="padding: 0">
        <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <BodyRightFourth3 ref="BodyRightFourth3"/>
        </el-col>
        <!-- <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <BodyRightFourth4 :timed-complain="timedComplain"/>
        </el-col> -->
      </el-col>
    </div>
  </el-col>
</template>

<script>
import BodyRightFourth1 from './components/bodyRightFourth1.vue'
import BodyRightFourth2 from './components/bodyRightFourth2.vue'
import BodyRightFourth3 from './components/bodyRightFourth3.vue'
import BodyRightFourth4 from './components/bodyRightFourth4.vue'
import moment from 'moment';

export default {
  name: 'HpscScreenVueIndex',
  components: {
    BodyRightFourth1,
    // BodyRightFourth2,
    BodyRightFourth3,
    // BodyRightFourth4,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      years: [
        {key: moment().add(-2, 'y').format("YYYY"), value: moment().add(-2, 'y').format("YYYY")},
        {key: moment().add(-1, 'y').format("YYYY"), value: moment().add(-1, 'y').format("YYYY")},
        {key: moment().year(), value: moment().year()},
      ]
    };
  },

  mounted() {
    
  },

  methods: {
    // 年份改变方法
    yearChange(value) {
      this.$refs['BodyRightFourth1'].getManifestCheckInfoHead(value)
      this.$refs['BodyRightFourth3'].getManifestExamTypeHead(value)
    }
  },
};
</script>

<style lang="scss" scoped>
.chart-title {
  color: #fff;
  font-weight: bolder;
  padding: 5px 10px;
  position: relative;
  font-size: .166667rem /* 10/60 */;
}

.year-select {
  margin-left: .083333rem /* 5/60 */;
  width: .833333rem /* 50/60 */;
}

::v-deep .year-select {
  .el-input__inner{
    height: .25rem /* 15/60 */;
    padding: 0 .083333rem /* 5/60 */;
    font-size: .166667rem /* 10/60 */;
    border: 1px solid #409EFF;
    color: #7edae8;
    background-color: #033065;
  }
  .el-input__suffix {
    display: none;
  }
}
</style>