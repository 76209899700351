<template>
  <el-col :lg="24" :md="24" :sm="24" :xs="24"  style="height: calc(30%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      集装箱作业时效
    </div>
    <el-col :lg="24" :md="24" :sm="24" :xs="24">
      <BodyRightCenter1 :chartData="conOpTimeData" @changeYear="getDContainerOperatinTimeList"/>
    </el-col>
  </el-col>
</template>

<script>
import BodyRightCenter1 from './components/bodyRightCenter1.vue'
import { queryDContainerOperatinTimeList } from './api'

export default {
  name: 'HpscScreenVueIndex',
  components: {
    BodyRightCenter1
  },
  data() {
    return {
      conOpTimeData: {
        months: [],
        iData: [],
        eData: [],
        iAva: '',
        eAva: ''
      }
    };
  },

  mounted() {
    this.getDContainerOperatinTimeList()
  },

  methods: {
    // 集装箱作业时效 -查询
    async getDContainerOperatinTimeList(yearPar) {
      const params = {
        year: yearPar || '1'
      }
      const res = await queryDContainerOperatinTimeList(params)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        if (rows.length > 0) {
          let months = []
          const iData = []
          const eData = []
          for (let i = 0; i < rows.length; i++) {
            months.push(rows[i].month + '月')
            if (rows[i].ieflag === 'I') {
              iData.push(rows[i].monthNum)
              this.conOpTimeData.iAva = rows[i].yearNum
            } else if (rows[i].ieflag === 'E') {
              eData.push(rows[i].monthNum)
              this.conOpTimeData.eAva = rows[i].yearNum
            }
          }
          months = Array.from(new Set(months))
          this.conOpTimeData.months = months
          this.conOpTimeData.iData = iData
          this.conOpTimeData.eData = eData
        } else {
          this.conOpTimeData.months = []
          this.conOpTimeData.iData = []
          this.conOpTimeData.eData = []
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>