<template>
  <div id="chart-line-pv-pv-box">
    <div class="chart-title">
      <img
        class="charts-icon"
        :src="require('@/assets/images/largescreen/right-arr.png')"
        alt=""
      />
      <span>查验类型</span>
    </div>
    <el-col :lg="8" :md="24" :sm="24" :xs="24">
      <GaugeChart4 :chartData="quarantineCheck"/>
    </el-col>
    <el-col :lg="8" :md="24" :sm="24" :xs="24">
      <GaugeChart5 :chartData="check"/>
    </el-col>
    <el-col :lg="8" :md="24" :sm="24" :xs="24">
      <GaugeChart6 :chartData="testCheck"/>
    </el-col>
  </div>
</template>

<script>
import resize from "@/utils/resize";
import GaugeChart4 from './gaugeCharts/gaugeChart4.vue'
import GaugeChart5 from './gaugeCharts/gaugeChart5.vue'
import GaugeChart6 from './gaugeCharts/gaugeChart6.vue'
import { queryManifestExamTypeHead } from '../api'

export default {
  mixins: [resize],
  components: {
    GaugeChart4,
    GaugeChart5,
    GaugeChart6
  },
  data() {
    return {
      dateObj: new Date(),
      quarantineCheck: 0,
      check: 0,
      testCheck: 0
    };
  },

  created() {},
  mounted() {
    this.getManifestExamTypeHead()
  },
  methods: {
    // 查验方式查-查询
    async getManifestExamTypeHead(yearPar) {
      const params = {
        year: yearPar || this.dateObj.getFullYear()
      }
      const res = await queryManifestExamTypeHead(params)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        this.quarantineCheck = Number(rows[0].检疫.split('%')[0])
        this.check = Number(rows[0].查验.split('%')[0])
        this.testCheck = Number(rows[0].检查.split('%')[0])
      } else {
        this.quarantineCheck = 0
        this.check = 0
        this.testCheck = 0
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#chart-line-pv-pv-box {
  height: calc(100%);

  .chart-title {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bolder;
    padding: 0px 10px 5px 10px;
    position: relative;
    font-size: .166667rem /* 10/60 */;

    .charts-icon {
      width: 20px;
      height: 20px;
    }
    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }
}
</style>