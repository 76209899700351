<template>
  <div id="chart-table-box">
    <dv-scroll-board id="check-type-table" :config="config"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'

export default {
  mixins: [resize],
  name: 'HpscScreenVueBodyRightFourth2',

  props: {
    timedComplain: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      chart: null,
      timedComplainDataList: [],
      config: {}
    }
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.setConfig()
    })
  },
  watch: {
    timedComplain: {
      handler(val) {
        var arr = []
        for (var i = 0; i < val.length; i++) {
          arr.push([
            this._parseTime(val[i].comDate, '{y}-{m}-{d} {h}:{i}:{s}'),
            '<span>' + val[i].comUser + '</span>',
            val[i].comContact,
           
          ])
        }
        this.timedComplainDataList = arr
        this.setConfig()
      },
      deep: true
    }
  },
  methods: {
    setConfig() {
      this.config = {
        index: true,
        header: ['货物名称', '查验次数'],
        data: this.timedComplainDataList,
        columnWidth: [50, 110, 110],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        rowNum: 5,
        align: ['center', 'center', 'center']
      }
    },
  }
};
</script>

<style lang="scss" scoped>
#chart-table-box {
  display: flex;
  // background: rgba(125, 155, 243, .2);
  height: 100%;
  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    // background: rgba(125, 155, 243, .2);
    position: relative;

    .charts-icon{
      width: 20px;
      height: 25px;
      position: absolute;
      left: 15px;
      top: 0;
    }
    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }

  #check-type-table {
    padding: 10px;
    height: calc(100% - 20px);
  }
}

::v-deep .dv-scroll-board .header {
  box-sizing: border-box;
  border: 1px solid #7edae8;
  background-color: none;
}

::v-deep .header-item {
  color: #01c1ca;
  font-weight: 900;
  font-size: 14px !important;
}
</style>