import * as request from '@/utils/request'

// 船舶作业查询
export const queryDContaOperationList = (params, headerPar) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDContaOperationList',
    method: 'post',
    params: headerPar,
    data: params
  })
}
