<template>
  <el-col :lg="24" :md="24" :sm="24" :xs="24"  style="height: calc(23%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      外贸集装箱
    </div>
    <el-col :lg="12" :md="24" :sm="24" :xs="24">
      <BodyLeftCenter1 :chartData="conMonthData" @changeYear="getDContainerMonthList"/>
    </el-col>
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      车辆动态
    </div>
    <el-col :lg="12" :md="24" :sm="24" :xs="24">
      <BodyLeftCenter2 :chartData="VehMonthData" @changeYear="getDVehicleMonthList"/>
    </el-col>
  </el-col>
</template>

<script>
import BodyLeftCenter1 from './components/bodyLeftCenter1.vue'
import BodyLeftCenter2 from './components/bodyLeftCenter2.vue'
import { queryDContainerMonthList, queryDVehicleMonthList, queryDVehicleTotalList } from './api'

export default {
  name: 'HpscScreenVueIndex',
  components: {
    BodyLeftCenter1,
    BodyLeftCenter2
  },
  data() {
    return {
      conMonthData: {
        months: [],
        iData: []
      },
      VehMonthData: {
        months: [],
        iData: [],
        eData: [],
        ieData: [],
        iTotal: 0,
        eTotal: 0,
      }
    };
  },

  mounted() {
    this.getDContainerMonthList()
    this.getDVehicleMonthList()
    this.getDVehicleTotalList()
    // console.log(window.innerWidth)
    // console.log(window.document.documentElement.getBoundingClientRect().width)
  },

  methods: {
    // 集装箱月度
    async getDContainerMonthList(yearPar) {
      const params = {
        year: yearPar || '1'
      }
      const res = await queryDContainerMonthList(params)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        if (rows.length > 0) {
          let months = []
          const iData = []
          for (let i = 0; i < rows.length; i++) {
            months.push(rows[i].month + '月')
            iData.push(rows[i].monthNum/10000)
          }
          months = Array.from(new Set(months))
          this.conMonthData.months = months
          this.conMonthData.iData = iData
        } else {
          this.conMonthData.months = []
          this.conMonthData.iData = []
        }
      }
    },
    // 车辆进出闸月度
    async getDVehicleMonthList(yearPar) {
      const params = {
        year: yearPar || '1'
      }
      const res = await queryDVehicleMonthList(params)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        if (rows.length > 0) {
          let months = []
          const iData = []
          const eData = []
          const ieData = []
          for (let i = 0; i < rows.length; i++) {
            months.push(Number(rows[i].month) + '月')
            if (rows[i].ieflag === 'I') {
              iData.push(rows[i].monthNum/10000)
            } else if (rows[i].ieflag === 'E') {
              eData.push(rows[i].monthNum/10000)
            }
          }
          months = Array.from(new Set(months))
          for (let i = 0; i < iData.length; i++) {
            ieData[i] = iData[i] + eData[i]
          }
          this.VehMonthData.months = months
          this.VehMonthData.iData = iData
          this.VehMonthData.eData = eData
          this.VehMonthData.ieData = ieData
        } else {
          this.VehMonthData.months = []
          this.VehMonthData.iData = []
          this.VehMonthData.eData = []
          this.VehMonthData.ieData = []
        }
      }
    },
    // 车辆进出闸总数
    async getDVehicleTotalList() {
      const res = await queryDVehicleTotalList({year: new Date().getFullYear()})
      if (res.data && res.code === 200) {
        const rows = res.data.rows
        if (rows.length > 0) {
          let iTotal = 0
          let eTotal = 0
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].ieflag === 'I') {
              iTotal += parseInt(rows[i].totalNum)
            } else if (rows[i].ieflag === 'E') {
              eTotal += parseInt(rows[i].totalNum)
            }
          }
          this.VehMonthData.iTotal = iTotal
          this.VehMonthData.eTotal = eTotal
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>