import * as request from '@/utils/request'

// 集装箱箱量统计查询--年度
export const queryDContainerTotalList = (params) => {
  return request.get('/mft/LargeScreenDisplay/queryDContainerTotalList', params)
}


// 车辆进出闸统计查询-月度
export const queryDVehicleTotalList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDVehicleTotalList',
    method: 'post',
    data: params
  })
}

// 船次统计查询-年度
export const queryDCargoTranList = (params) => {
  return request.get('/mft/LargeScreenDisplay/queryDCargoTranList', params)
}