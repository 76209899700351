<template>
  <el-col :lg="24" :md="12" :sm="24" :xs="24" style="height: calc(27%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      集装箱海事处罚汇总
    </div>
    <el-col :lg="24" :md="12" :sm="24" :xs="24">
      <div id="conta-punish-chart-table-box">
        <dv-scroll-board ref="conta-punish-chart-table" id="conta-punish-chart-table" :config="config"/>
      </div>
    </el-col>
  </el-col>
</template>

<script>
import resize from '@/utils/resize'
import { queryDContaPenalizeList } from './api'

export default {
  mixins: [resize],
  name: "HpscScreenVueBodyLeftBottom",
  data() {
    return {
      chart: null,
      timedComplainDataList: [],
      config: {},
      tableHeaderWidth: 0,
      pageNum: 1,
      pageSize: 10
    }
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.setConfig()
      this.getDContaPenalizeList()
    })
  },
  watch: {
  },
  methods: {
    async getDContaPenalizeList() {
      const headerPar = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const res = await queryDContaPenalizeList({}, headerPar)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        const arr = []
        if (rows.length > 0) {
          for (let i = 0; i < rows.length; i++) {
            const dataArr = [
              rows[i].consigner,
              rows[i].agency,
              rows[i].portOfDeparture,
              rows[i].portOfDestination,
              rows[i].consignee,
              rows[i].cargoName,
            ]
            arr.push(dataArr)
          }
          this.timedComplainDataList = arr
          this.$refs['conta-punish-chart-table'].updateRows(this.timedComplainDataList)
        }
      }
    },
    setConfig() {
      this.tableHeaderWidth = document.querySelector('#conta-punish-chart-table').offsetWidth
      this.config = {
        index: true,
        header: ['发货方', '代理', '始发港', '目的港', '收货人', '货名'],
        data: this.timedComplainDataList,
        // columnWidth: [50, 200, 200, 100, 100, 150, 110],
        columnWidth: [
          this.tableHeaderWidth * 0.051,
          this.tableHeaderWidth * 0.22,
          this.tableHeaderWidth * 0.22,
          this.tableHeaderWidth * 0.11,
          this.tableHeaderWidth * 0.11,
          this.tableHeaderWidth * 0.169,
          this.tableHeaderWidth * 0.118,
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        rowNum: 5,
        align: ['center', 'center', 'center', 'center', 'center', 'center', 'center'],
        headerHeight: this.cusFontSize(0.516)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#conta-punish-chart-table-box {
  display: flex;
  // background: rgba(125, 155, 243, .2);
  height: 100%;
  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    // background: rgba(125, 155, 243, .2);
    position: relative;
  }

  #conta-punish-chart-table {
    padding: 10px 0;
    height: calc(100% - 20px);
  }
}
</style>