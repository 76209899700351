<template>
  <el-col :lg="24" :md="24" :sm="24" :xs="24"  style="height: calc(20%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      外贸吞吐量
    </div>
    <el-col :lg="8" :md="24" :sm="24" :xs="24">
      <BodyLeftTop1 :chartData="conYearData"/>
    </el-col>
    <el-col :lg="8" :md="24" :sm="24" :xs="24">
      <BodyLeftTop2 :chartData="vehYearData"/>
    </el-col>
    <el-col :lg="8" :md="24" :sm="24" :xs="24">
      <BodyLeftTop3 :chartData="shipYearData"/>
    </el-col>
  </el-col>
</template>

<script>
import resize from '@/utils/resize'
import BodyLeftTop1 from './components/bodyLeftTop1.vue'
import BodyLeftTop2 from './components/bodyLeftTop2.vue'
import BodyLeftTop3 from './components/bodyLeftTop3.vue'
import { queryDContainerTotalList, queryDVehicleTotalList, queryDCargoTranList } from './api'

export default {
  name: 'HpscScreenVueIndex',
  mixins: [resize],
  components: {
    BodyLeftTop1,
    BodyLeftTop2,
    BodyLeftTop3,
  },
  data() {
    return {
      bodyLeftTop1Data: [],
      conYearData: {
        years: [],
        iTotal: [],
        oTotal: [],
        fullTotal: ''
      },
      vehYearData: {
        years: [],
        iTotal: [],
        oTotal: [],
        fullTotal: ''
      },
      shipYearData: {
        years: [],
        iTotal: [],
        oTotal: [],
        fullTotal: ''
      }
    };
  },

  mounted() {
    this.getDContainerTotal()
    this.getDVehicleTotalList()
    this.getDCargoTranList()
    this.buildShape()

  },
  methods: {
    // 集装箱年度查询
    async getDContainerTotal() {
      const params = {
      }
      const res = await queryDContainerTotalList(params)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows.reverse()
        if (rows.length > 0) {
          let years = []
          const iTotal = []
          const oTotal = []
          for (let i = 0; i < rows.length; i++) {
            years.push(rows[i].year)
            this.conYearData.fullTotal = rows[i].dataText1
            if (rows[i].ieflag === 'I') {
              iTotal.push(rows[i].totalNum/10000)
            } else {
              oTotal.push(rows[i].totalNum/10000)
            }
          }
          years = Array.from(new Set(years))
          if (this.conYearData.fullTotal) {
            this.conYearData.fullTotal = this.conYearData.fullTotal.replace(/,/g, '')
          } else {
            this.conYearData.fullTotal = '0'
          }
          this.conYearData.years = years
          this.conYearData.iTotal = iTotal
          this.conYearData.oTotal = oTotal
          
        }
      }
    },
    // 车辆进出闸年度查询
    async getDVehicleTotalList() {
      const params = {
      }
      const res = await queryDVehicleTotalList(params)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows.reverse()
        if (rows.length > 0) {
          let years = []
          const iTotal = []
          const oTotal = []
          for (let i = 0; i < rows.length; i++) {
            years.push(rows[i].year)
            this.vehYearData.fullTotal = rows[i].dataText1
            if (rows[i].ieflag === 'I') {
              iTotal.push(rows[i].totalNum/10000)
            } else {
              oTotal.push(rows[i].totalNum/10000)
            }
          }
          years = Array.from(new Set(years))
          if (this.vehYearData.fullTotal) {
            this.vehYearData.fullTotal = this.vehYearData.fullTotal.replace(/,/g, '')
          } else {
            this.vehYearData.fullTotal = '0'
          }
          this.vehYearData.years = years
          this.vehYearData.iTotal = iTotal
          this.vehYearData.oTotal = oTotal
        }
      }
    },
    // 船次统计查询-年度
    async getDCargoTranList() {
      const params = {
      }
      const res = await queryDCargoTranList(params)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows.reverse()
        if (rows.length > 0) {
          let years = []
          const iTotal = []
          const oTotal = []
          for (let i = 0; i < rows.length; i++) {
            years.push(rows[i].yearTime)
            this.shipYearData.fullTotal = rows[i].datatext1
            if (rows[i].ieflag === 'I') {
              iTotal.push(rows[i].datatext/10000)
            } else {
              oTotal.push(rows[i].datatext/10000)
            }
          }
          years = Array.from(new Set(years))
          if (this.shipYearData.fullTotal) {
            this.shipYearData.fullTotal = this.shipYearData.fullTotal.replace(/,/g, '')
          } else {
            this.shipYearData.fullTotal = '0'
          }
          this.shipYearData.years = years
          this.shipYearData.iTotal = iTotal
          this.shipYearData.oTotal = oTotal
        }
      }
    },
    // 绘制立体柱状图
    buildShape() {
      const that = this
      // 绘制左侧面
      const CubeLeft = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          // 会canvas的应该都能看得懂，shape是从custom传入的
          const xAxisPoint = shape.xAxisPoint;
          const c0 = [shape.x - that.cusFontSize(0.1 /* 6/60 */), shape.y + that.cusFontSize(0.05 /* 3/60 */)]; //右上角
          const c1 = [shape.x - that.cusFontSize(0.216667 /* 13/60 */), shape.y - that.cusFontSize(0.016667 /* 1/60 */)]; //左上角
          const c2 = [xAxisPoint[0] - that.cusFontSize(0.216667 /* 13/60 */), xAxisPoint[1] - that.cusFontSize(0.083333 /* 5/60 */)]; //左下角
          const c3 = [xAxisPoint[0] - that.cusFontSize(0.1 /* 6/60 */), xAxisPoint[1]]; //右下角
          ctx
            .moveTo(c0[0], c0[1])
            .lineTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .closePath();
        },
      });

      // 绘制右侧面
      const CubeRight = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint;
          const c1 = [shape.x - that.cusFontSize(0.1 /* 6/60 */), shape.y]; //左上
          const c2 = [xAxisPoint[0] - that.cusFontSize(0.1 /* 6/60 */), xAxisPoint[1]]; //左下
          const c3 = [xAxisPoint[0] + that.cusFontSize(0.033333 /* 2/60 */), xAxisPoint[1] - that.cusFontSize(0.083333 /* 5/60 */)]; //右下
          const c4 = [shape.x + that.cusFontSize(0.033333 /* 2/60 */), shape.y - that.cusFontSize(0.05 /* 3/60 */)]; //右上
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });

      // 绘制顶面
      const CubeTop = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0,
        },
        buildPath: function (ctx, shape) {
          const c1 = [shape.x - that.cusFontSize(0.083333 /* 5/60 */), shape.y + that.cusFontSize(0.033333 /* 2/60 */)]; //下
          const c2 = [shape.x + that.cusFontSize(0.033333 /* 2/60 */), shape.y - that.cusFontSize(0.033333 /* 2/60 */)]; //右点
          const c3 = [shape.x - that.cusFontSize(0.083333 /* 5/60 */), shape.y - that.cusFontSize(0.083333 /* 5/60 */)]; //上
          const c4 = [shape.x - that.cusFontSize(0.216667 /* 13/60 */), shape.y - that.cusFontSize(0.016667 /* 1/60 */)]; //左
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath();
        },
      });

      this.$echarts.graphic.registerShape("CubeLeft", CubeLeft);
      this.$echarts.graphic.registerShape("CubeRight", CubeRight);
      this.$echarts.graphic.registerShape("CubeTop", CubeTop);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>