<template>
  <div id="chart-line-pv-pv-box">
    <!-- <div class="chart-title">
      <img class="charts-icon" :src="require('@/assets/images/largescreen/charts-icon.png')" alt="">
      <dv-decoration-1/>
      <span>堆存时间</span>
    </div> -->
    <div class="inport-info">
      <div class="info-box" v-for="(item, index) in chartData.stDataList" :key="index">
        <div>
          {{item.name}}
        </div>
        <div class="num-div">
          {{item.value}} <span style="color: #fff">TEU</span>
        </div>
      </div>
    </div>
    <div id="inporttime-pie-pv"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'

export default {
  mixins: [resize],
  name: 'HpscScreenVueBodyRightTop2',
  props: {
    chartData: {
      type: Object
    }
  },
  data() {
    return {
      chart: null,
      mapJsonData: {
        pv: [],
        visitDate: [],
      },
    }
  },
  watch: {
    chartData: {
      handler(newVal, oldVal) {
        this.initChart();
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(document.getElementById('inporttime-pie-pv'))
      this.setOptions()
    },
    setOptions() {
      this.chart.setOption({
        color: ['#e87b2c', '#9bff51', '#ffe200', '#0092ff', '#00dfcc'],
        tooltip: {
          show: false,
          trigger: 'item',
          textStyle: {
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          formatter: function (params) {
            const relVal = 
              params.marker + params.name + '<br/>' + 
              'TEU: ' + params.value + '<br/>' +
              '占比: ' + params.percent + '%'
            return relVal
          }
        },
        legend: [{
          bottom: '20%',
          left: '0%',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: '#fff',
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          data: ['2天内', '2-5天'],
        },{
          bottom: '40%',
          left: '0%',
          itemWidth: 10,
          itemHeight: 10,
          textStyle: {
            color: '#fff',
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          data: ['5-7天', '7天以上'],
        }],
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['70%', '65%'],
            label: {
              show: true,
              padding:[0,5,0,5],
              fontSize: this.cusFontSize(0.166667 /* 10/60 */),
              formatter(params) {
                return params.percent + '%';
              },
            },
            labelLine: {
              length: 0,
              length2: 0,
            },
            data: this.chartData.stDataList
          }
        ]
      })
    },
  }
};
</script>

<style lang="scss" scoped>
#chart-line-pv-pv-box {
  // background: rgba(125, 155, 243, .2);
  height: calc(100%);
  //margin-bottom: 10px;

  .chart-title {
    display: flex;
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    // background: rgba(125, 155, 243, .2);
    position: relative;

    .charts-icon{
      width: 20px;
      height: 25px;
      position: absolute;
      left: 15px;
      top: 0;
    }
    .dv-decoration-1 {
      width: 50px;
      height: 18px;
    }
  }

  .inport-info {
    position: absolute;
    display: flex;
    color: #fff;
    font-size: .166667rem /* 10/60 */;
    text-align: center;
    .info-box:not(:last-child) {
      border-right: 1px solid rgb(217, 217, 217, 0.3);
    }
    .info-box {
      padding: 0 2px;
      .num-div {
        font-weight: 900;
        color: #00f6ff;
        span {
          color: #fff;
          font-weight: 100;
        }
      }
    }
  }

  #inporttime-pie-pv {
    height: calc(100%);
  }
}

</style>