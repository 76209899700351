<template>
  <div id="chart-line-pv-pv-box">
    <div class="chart-title">
      <span>年度</span>
      <el-select class="year-select" v-model="year" size="small" @change="yearChange">
        <el-option v-for="(item, index) in years" :key="index" :value="item.value" :label="item.key" />
      </el-select>
    </div>
    <div class="in-out-info">
      <div class="info-div">
        <img
          class="info-icon"
          :src="require('@/assets/images/largescreen/title-img.png')"
          alt=""
        />
        <div class="text-div">
          <div style="font-size: 0.2rem; color: #00fff6">{{this.chartData.iTotal}}</div>
          <div style="font-size: 0.166667rem;">进闸车次</div>
        </div>
      </div>
      <div class="info-div">
        <img
          class="info-icon"
          :src="require('@/assets/images/largescreen/money-icon.png')"
          alt=""
        />
        <div class="text-div">
          <div style="font-size: 0.2rem; color: #ffcd00">{{this.chartData.eTotal}}</div>
          <div style="font-size: 0.166667rem;">出闸车次</div>
        </div>
      </div>
    </div>
    <div id="car-douline-pv"/>
  </div>
</template>

<script>
import resize from '@/utils/resize'
import echarts from "echarts";
import moment from 'moment';

export default {
  mixins: [resize],
  name: 'HpscScreenVueBodyLeftCenter2',
  props: {
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
      mapJsonData: {
        pv: [],
        visitDate: [],
      },
      year: '1',
      years: [
        {key: moment().add(-2, 'y').format("YYYY"), value: moment().add(-2, 'y').format("YYYY")},
        {key: moment().add(-1, 'y').format("YYYY"), value: moment().add(-1, 'y').format("YYYY")},
        {key: moment().year(), value: moment().year()},
        {key: '近一年', value: '1'},
      ]
    }
  },
  watch: {
    chartData: {
      handler(newVal, oldVal) {
        this.initChart();
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(document.getElementById('car-douline-pv'))
      this.setOptions()
    },
    setOptions() {
      this.chart.setOption({
        color: ['#00acff', '#ffcd00'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          formatter: function (params) {
            const color = ['#00acff', '#ffcd00']
            let relVal = params[0].name
            // 倒叙展示
            for (let i = params.length - 1, l = params.length; i >= 0; i--) {
              if (i === 1 || i === 0) {
                relVal += '<br/>' + 
                `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[i]};"></span>` + 
                params[i].seriesName + ': ' + params[i].value + '万'
              }
            }
            return relVal
          }
        },
        legend: {
          top: '-4',
          itemHeight: 4,
          textStyle: {
            color: '#fff',
            fontSize: this.cusFontSize(0.166667 /* 10/60 */)
          },
          data: ['进闸车次', '出闸车次']
        },
        grid: {
          top: this.cusFontSize(0.333333 /* 20/60 */),
          left: '0',
          right: '0',
          bottom: this.cusFontSize(0.833333 /* 50/60 */),
          containLabel: true
        },
        xAxis: [
          {
            name: '年份',
            type: 'category',
            axisTick: { show: false },
            axisLabel: {
              interval:0,
              fontSize: this.cusFontSize(0.166667 /* 10/60 */),
              textStyle:{
                color:'#7edae8',  //坐标的字体颜色
              },
            },
            data: this.chartData.months
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '单位: 万',
            splitNumber: 4,
            nameTextStyle: {
              color: '#7edae8',
              fontSize: this.cusFontSize(0.166667 /* 10/60 */),
              padding: window.innerWidth < 2304 ? [0, 0, -8, 0] : null
            },
            axisLabel: {
              textStyle:{
                color:'#7edae8',  //坐标的字体颜色
                fontSize: this.cusFontSize(0.2 /* 10/60 */)
              },
            },
            splitLine: {
              //分割线配置
              lineStyle: {
                color: "rgb(255,255,255,0)",
              },
            },
          }
        ],
        series: [
          {
            name: '进闸车次',
            type: 'bar',
            stack: 'IO',
            emphasis: {
              focus: 'series'
            },
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'#00acff'    //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            },
            barMaxWidth: this.cusFontSize(0.166667 /* 10/60 */),
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#2450F0',
                  },
                  {
                    offset: 1,
                    color: '#2FCAFD',
                  },
                ]),
              }
            },
            data: this.chartData.iData
          },
          {
            name: '出闸车次',
            type: 'bar',
            stack: 'IO',
            emphasis: {
              focus: 'series'
            },
            areaStyle:{         //2.基础面积图。区域填充样式
              color:'#F7D543'    //支持RGB、ALPHA通道+RGBA、十六进制、渐变色、纹理填充
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#F7B343',
                  },
                  {
                    offset: 1,
                    color: '#F7D543',
                  },
                ]),
              }
            },
            barMaxWidth: this.cusFontSize(0.166667 /* 10/60 */),
            data: this.chartData.eData
          },
          {
            z: 2,
            type: 'pictorialBar',
            data: this.chartData.iData,
            symbol: 'diamond',
            symbolOffset: [0, '50%'],
            symbolSize: [this.cusFontSize(0.166667 /* 10/60 */), this.cusFontSize(0.166667 /* 10/60 */) * 0.5],
            itemStyle: {
              normal: {
                color: '#00acff'
              }
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: this.chartData.iData,
            symbol: 'diamond',
            symbolOffset: ['0', '-50%'],
            symbolSize: [this.cusFontSize(0.166667 /* 10/60 */), this.cusFontSize(0.166667 /* 10/60 */) * 0.5],
            itemStyle: {
              normal: {
                borderWidth: 0,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#3FA1FB',
                  },
                  {
                    offset: 1,
                    color: '#41B4F8',
                  },
                  // {
                  //   offset: 0,
                  //   color: '#4EA9D5',
                  // },
                  // {
                  //   offset: 1,
                  //   color: '#00acff',
                  // },
                ])
              }
            },
          },
          {
            z: 3,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: this.chartData.ieData,
            symbol: 'diamond',
            symbolOffset: [0, '-50%'],
            symbolSize: [this.cusFontSize(0.166667 /* 10/60 */), this.cusFontSize(0.166667 /* 10/60 */) * 0.5],
            itemStyle: {
              normal: {
                borderWidth: 0,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#F7C743',
                  },
                  {
                    offset: 1,
                    color: '#F7D543',
                  },
                ])
              }
            },
          },
        ]
      })
    },
    // 年份改变方法
    yearChange(value) {
      this.$emit('changeYear', value)
    }
  }
};
</script>

<style lang="scss" scoped>
#chart-line-pv-pv-box {
  height: calc(100%);

  .chart-title {
    color: #fff;
    font-weight: bolder;
    padding: 5px 10px;
    position: relative;
    font-size: .166667rem /* 10/60 */;
  }

  #car-douline-pv {
    height: calc(100%);
  }
}

.year-select {
  margin-left: .083333rem /* 5/60 */;
  width: .833333rem /* 50/60 */;
}

::v-deep .year-select {
  .el-input__inner{
    height: .25rem /* 15/60 */;
    padding: 0 .083333rem /* 5/60 */;
    font-size: .166667rem /* 10/60 */;
    border: 1px solid #409EFF;
    color: #7edae8;
    background-color: #033065;
  }
  .el-input__suffix {
    display: none;
  }
}

.in-out-info {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 0 10px;
  .info-div {
    display: flex;
    align-items: center;
    .info-icon {
      height: .333333rem /* 20/60 */;
      width: .333333rem /* 20/60 */;
    }
  }
}
</style>