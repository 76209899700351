<template>
  <div style="height: calc(100%)">
    <div id="quarantine-check-gauge-pv" />
  </div>
</template>

<script>
import resize from "@/utils/resize";

export default {
  mixins: [resize],
  name: 'HpscScreenVueGaugeChart4',
  props: {
    chartData: {
      type: Number
    }
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      handler(val) {
        this.initChart();
      },
      deep: true
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },

  methods: {
    initChart() {
      this.chart = this.$echarts.init(
        document.getElementById("quarantine-check-gauge-pv")
      );
      this.setOptions();
    },
    setOptions() {
      this.chart.setOption({
        series: [
          {
            type: 'gauge',
            startAngle: 190,
            endAngle: -10,
            center:['50%','60%'],
            axisLine: {
              lineStyle: {
                width: this.cusFontSize(-0.2 /* 12/60 */),
                color: [
                  [1, new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {offset: 0.1, color: '#FFC600'},
                    {offset: 0.6, color: '#30D27C'},
                    {offset: 1, color: '#0B95FF'},
                  ])]
                ]
              }
            },
            axisTick: {
              splitNumber: 1,
              distance: 0,
              length: 2,
              lineStyle: {
                color: '#fff',
                width: 1
              }
            },
            splitLine: {
              show: false,
              length: 2,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            axisLabel: {
              show: false,
              color: 'inherit',
              distance: 40,
              fontSize: 12
            },
            // 指针配置
            pointer: {
              show: true,
              offsetCenter: ["20%", "50%"],
              length: "70%",
              width: 3,
              
            },
            title: {
              fontSize: this.cusFontSize(0.2 /* 12/60 */),
              offsetCenter: ["0", "85%"],
              color: '#fff'
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["0", "45%"],
              formatter: '{value}%',
              color: '#00fff6',
              fontSize: this.cusFontSize(0.166667 /* 12/60 */),
              fontWight: 900
            },
            data: [
              {
                value: this.chartData,
                name: '检疫'
              }
            ]
          }
        ]
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#quarantine-check-gauge-pv {
  height: calc(60%);
}
</style>