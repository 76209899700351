<template>
  <el-col :lg="24" :md="24" :sm="24" :xs="24"  style="height: calc(20%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      在港集装箱
    </div>
    <el-col :lg="12" :md="24" :sm="24" :xs="24">
      <BodyRightTop1 :chartData="icData"/>
    </el-col>
    <el-col :lg="12" :md="24" :sm="24" :xs="24">
      <BodyRightTop2 :chartData="stData"/>
    </el-col>
  </el-col>
</template>

<script>
import BodyRightTop1 from './components/bodyRightTop1.vue'
import BodyRightTop2 from './components/bodyRightTop2.vue'
import { queryDInContainerList, queryDInContaierIeFlagList, queryDContainerStorageList } from './api'

export default {
  name: 'HpscScreenVueIndex',
  components: {
    BodyRightTop1,
    BodyRightTop2
  },
  data() {
    return {
      icData: {
        journeyList: [],
        iData: [],
        eData: [],
        oData: [],
        ieoTotalList: []
      },
      stData: {}
    };
  },

  mounted() {
    this.getDInContainerList()
    this.getDInContaierIeFlagList()
    this.getDContainerStorageList()
  },

  methods: {
    // 在港集装箱查询-按照集装箱类型
    async getDInContainerList() {
      const res = await queryDInContainerList({dataText: new Date().getFullYear()})
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        if (rows.length > 0) {
          let journeyList = []
          const iData = []
          const eData = []
          const oData = []
          for (let i = 0; i < rows.length; i++) {
            journeyList.push(rows[i].route)
            if (rows[i].ieflag === 'I') {
              iData.push(rows[i].contaNum/10000)
            }
            if (rows[i].ieflag === 'E') {
              eData.push(rows[i].contaNum/10000)
            }
            if (rows[i].ieflag === 'O') {
              oData.push(rows[i].contaNum/10000)
            }
          }
          journeyList = Array.from(new Set(journeyList))
          this.icData.journeyList = journeyList
          this.icData.iData = iData
          this.icData.eData = eData
          this.icData.oData = oData
        }
      }
    },
    // 在港集装箱查询-按照集装箱类型
    async getDInContaierIeFlagList() {
      const res = await queryDInContaierIeFlagList({dataText: new Date().getFullYear()})
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        const ieoTotalList = []
        if (rows.length > 0) {
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].ieflag === 'I') {
              ieoTotalList.push({name: '进口箱', value: rows[i].contaNum})
            }
            if (rows[i].ieflag === 'E') {
              ieoTotalList.push({name: '出口箱', value: rows[i].contaNum})
            }
            if (rows[i].ieflag === 'O') {
              ieoTotalList.push({name: '中转箱', value: rows[i].contaNum})
            }
          }
        } else {
          ieoTotalList.push({name: '进口箱', value: 0})
          ieoTotalList.push({name: '出口箱', value: 0})
          ieoTotalList.push({name: '中转箱', value: 0})
        }
        this.icData.ieoTotalList = ieoTotalList
      }
    },
    // 集装箱在港堆存时间-查询
    async getDContainerStorageList() {
      const res = await queryDContainerStorageList({})
      if (res.data.code === 200) {
        const rows = res.data.rows
        if (rows.length > 0) {
          const stDataList = []
          stDataList.push({name: '2天内', value: rows[0].twoNum})
          stDataList.push({name: '2-5天', value: rows[0].fiveNum})
          stDataList.push({name: '5-7天', value: rows[0].sevenNum})
          stDataList.push({name: '7天以上', value: rows[0].moreNum})
          this.stData = rows[0]
          this.stData.stDataList = stDataList
        }
      }
    }
    
  },
};
</script>

<style lang="scss" scoped>
.module-title {
  font-size: 0.18rem;
  // background-position: center;
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  // background-image: url("../../../assets/images/largescreen/title-bg.png");
}
</style>