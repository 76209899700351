<template>
  <el-col :lg="24" :md="24" :sm="24" :xs="24"  style="height: calc(20%); display: flex">
    <img
      class="title-icon"
      :src="require('@/assets/images/largescreen/title.png')"
      alt=""
    />
    <div style="writing-mode:vertical-rl; color: #fff">
      业务动态
    </div>
    <el-col :lg="24" :md="24" :sm="24" :xs="24">
      <BodyRightSecond1 :timedComplainDataList="timedComplainDataList"/>
    </el-col>
  </el-col>
</template>

<script>
import BodyRightSecond1 from './components/bodyRightSecond1.vue'
import { queryDShipOperationList } from './api'
import moment from 'moment'

export default {
  name: 'HpscScreenVueIndex',
  components: {
    BodyRightSecond1
  },
  data() {
    return {
      timedComplainDataList: [],
      pageNum: 1,
      pageSize: 10,
      typeEnums: {
        'S10_ADVISED': '预报',
        'S20_INBOUND': '确报',
        'S30_ECIN': '进场设备',
        'S40_YARD': '堆场',
        'S50_ECOUNT': '出场谁被',
        'S60_LOADED': '装船',
        'S70_DEPARTED': '离场',
        'S99_RETIRED': '失效'
      }
    };
  },

  mounted() {
    this.getDShipOperationList()
  },

  methods: {
    async getDShipOperationList() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const res = await queryDShipOperationList(params)
      if (res.data && res.data.code === 200) {
        const rows = res.data.rows
        const arr = []
        if (rows.length > 0) {
          for (let i = 0; i < rows.length; i++) {
            const dataArr = [
              rows[i].ieflag === 'I' ? '内贸' : '外贸',
              rows[i].conta,
              rows[i].voyage,
              rows[i].departurePort,
              rows[i].destinationPort,
              rows[i].billno,
              this.typeEnums[rows[i].type] || rows[i].type,
              rows[i].oppoTime ? moment(rows[i].oppoTime).format('YY-MM-DD hh:mm') : '',
            ]
            arr.push(dataArr)
          }
          this.timedComplainDataList = arr
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>