import * as request from '@/utils/request'

// 货物处罚汇总-查询
export const queryDContaPenalizeList = (params,headerPar) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDContaPenalizeList',
    method: 'post',
    params: headerPar,
    data: params
  })
}