import * as request from '@/utils/request'

// 货物运输统计表 -货物年度统计查询
export const queryDCargoTranTotalList = (params) => {
  return request.request({
    url: '/mft/LargeScreenDisplay/queryDCargoTranTotalList',
    method: 'post',
    data: params
  })
}

// 货物运输统计表 -货物每年统计查询
export const queryDCargoTranTotalByYearList = (params) => {
  return request.get('/mft/LargeScreenDisplay/queryDCargoTranTotalByYearList', params)
}